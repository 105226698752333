const createData = (name, /* calories, fat, carbs, protein, price,  */quantity, comment) => {
  return {
    name,
    //calories,
    //fat,
    //carbs,
    //protein,
    //price,
    quantity,
    comment
  };
};

export default createData;